import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getApplicationData } from 'selectors/getApplicationData';
import { setCardData } from 'handlers/cardData';
import { getCardData } from 'selectors/getCardData';

import ShareModal, { SHARE_MESSAGE } from 'components/ShareModal/ShareModal';
import StatusButton from 'components/Common/Buttons/StatusButton';
import { ReactComponent as CheckIcon } from 'images/green-check.svg';

import styles from './YourFinances.module.scss';

const PlanneryCardInformation = ({ cardTextLabel, short }: { cardTextLabel?: string; short?: boolean }) => {
  const dispatch = useDispatch();

  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  const { cardApplied, cardReferralLink } = useSelector(getApplicationData).application!;

  const data = useSelector(getCardData);

  useEffect(() => {
    analytics.track('Card CTA Viewed', { applied: cardApplied });
    if (cardApplied) {
      dispatch(
        setCardData({
          applied: cardApplied,
          referralLink: cardReferralLink,
        }),
      );
    }
  }, []);

  return (
    <>
      {short ? (
        <>
          {data.applied && (
            <StatusButton className={styles.statusButton} label="On the waitlist" icon={<CheckIcon />} />
          )}
        </>
      ) : (
        <div className={styles.planneryCardInfoContainer}>
          <p className={styles.sectionTitle}>Get the Plannery Card!</p>
          <ul>
            <li>5% back on professional expenses</li>
            <li>2% back on everything else</li>
            {cardTextLabel && <li>{cardTextLabel}</li>}
            <li>Uses AI to reduce your financial stress</li>
            <li>No annual or monthly fees</li>
          </ul>

          {data.applied && (
            <StatusButton className={styles.statusButton} label="On the waitlist" icon={<CheckIcon />} />
          )}
        </div>
      )}
      {showShareModal && (
        <ShareModal
          shareMessage={SHARE_MESSAGE}
          handleClose={() => setShowShareModal(false)}
          referralLink={data.referralLink!}
        />
      )}
    </>
  );
};

export default PlanneryCardInformation;
