import React, { useEffect } from 'react';
import alloy from '@alloyidentity/web-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { getKyc } from 'selectors/kyc';
import { getApplicationData } from 'selectors/getApplicationData';
import { getEnvironment, Environments } from 'utils/getEnvironment';
import { AlloyKycResult } from 'enums/FlowNextResults';
import { KycStatus } from 'handlers/kyc';
import { completeVerificationStep } from 'handlers/applicationData';
import { VerificationStep } from 'components/Verification/verificationSteps';
import PartnerBanner from 'components/Common/PartnerBanner';
import FormContainer from 'components/LoanForm/FormContainer';
import useVerificationSteps from 'components/Verification/useVerificationSteps';
import { VerificationStepComponent } from 'components/Verification/VerificationNavigation/VerificationNavigation';
import Button from 'components/Button';
import Loader from 'components/Loader';

import { ReactComponent as AlloyLogo } from 'images/alloy.svg';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { alloyKyc } from 'thunks';

import styles from './Alloy.module.scss';

enum Status {
  Completed = 'completed',
  WaitingReview = 'waiting_review',
  DataRequest = 'data_request',
  PendingDocuments = 'pending_documents',
  PendingAction = 'pending_action',
  Running = 'running',
}

const Alloy = ({ handleNext }: VerificationStepComponent): JSX.Element => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { verificationStepsStatus } = useVerificationSteps();
  const { isLoading, journeyApplicationToken, journeyToken } = useSelector(getKyc);
  const { application } = useSelector(getApplicationData);

  if (!application) {
    throw new Error('Missing application data');
  }

  useEffect(() => {
    const run = async () => {
      if (!journeyApplicationToken) {
        await dispatchWithUnwrap(alloyKyc(application.id));
      }
    };

    run();
  }, []);

  const openAlloy = () => {
    const alloyInitParams = {
      key: process.env.REACT_APP_ALLOY_SDK_KEY!,
      production: getEnvironment() === Environments.Production,
      color: { primary: '#795af7', secondary: '#454b54' },
      journeyApplicationToken,
      journeyToken,
      isNext: true,
    };
    alloy.init(alloyInitParams);
    alloy.open(callback);
  };

  // Callback
  const callback = async (data: any) => {
    analytics.track(`Alloy ${data.status}`);
    const { status } = await dispatchWithUnwrap(alloyKyc(application.id));
    if (data.status !== Status.PendingDocuments) {
      if (status === KycStatus.Approved) {
        dispatch(completeVerificationStep(VerificationStep.UploadLicense));
        analytics.track('KYC Approved');
        handleNext(AlloyKycResult.Continue);
      } else if (status === KycStatus.Rejected) {
        analytics.track('KYC Rejected');
        handleNext(AlloyKycResult.Rejected);
      } else {
        handleNext();
      }
    }
  };

  const renderAction = () =>
    verificationStepsStatus[VerificationStep.UploadLicense] ? (
      <div className={styles.verified}>
        <CheckIcon className={styles.checkIcon} />
        <p className={styles.verifiedLabel}>Verified</p>
      </div>
    ) : (
      <Button className={styles.button} onClick={openAlloy}>
        Get Started
      </Button>
    );

  return (
    <FormContainer title="Upload License" subtitle="Plannery partners with Alloy to verify your identity.">
      <PartnerBanner name="Alloy" logo={<AlloyLogo />} />
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        renderAction()
      )}
    </FormContainer>
  );
};

export default Alloy;
