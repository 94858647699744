import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { completeVerificationStep } from 'handlers/applicationData';
import { VerificationResult } from 'enums/FlowNextResults';

import {
  VerificationStep,
  VerificationStepProgress,
  getNextUncompletedStep,
  getVerificationStep,
} from './verificationSteps';

const defaultStatuses: VerificationStepProgress = {
  [VerificationStep.EmploymentDetails]: false,
  [VerificationStep.UploadPaystubs]: false,
  [VerificationStep.CheckingAccount]: false,
  [VerificationStep.UploadLicense]: false,
};

const useVerificationSteps = (current?: VerificationStep) => {
  const dispatch = useDispatch();

  const { application } = useSelector(getApplicationData);

  const [currentStep, setCurrentStep] = useState<VerificationStep>(current ?? VerificationStep.EmploymentDetails);

  const verificationStepsStatus = application?.verificationStepProgress || defaultStatuses;
  const nextStep = getNextUncompletedStep(verificationStepsStatus, currentStep);

  const firstUncompletedStep: VerificationStep =
    (Object.keys(verificationStepsStatus).find(
      (step) => !verificationStepsStatus[step as VerificationStep],
    ) as VerificationStep) ?? VerificationStep.EmploymentDetails;

  const isLastStep = Object.values(verificationStepsStatus).filter((step) => !step).length === 1;
  const verificationStarted = Object.values(verificationStepsStatus).some((step) => step);
  const verificationCompleted = Object.values(verificationStepsStatus).every((step) => step);

  const handleNextStep = (result?: VerificationResult) => {
    if (result && result !== VerificationResult.DoThisLater) {
      analytics.track(`${getVerificationStep(currentStep)?.name} Completed`);
      dispatch(completeVerificationStep(currentStep));
    }

    setCurrentStep(nextStep);

    analytics.track(`${getVerificationStep(nextStep)?.name} Entered`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return {
    currentStep,
    isLastStep,
    handleNextStep,
    setCurrentStep,
    firstUncompletedStep,
    verificationStarted,
    verificationStepsStatus,
    verificationCompleted,
  };
};

export default useVerificationSteps;
