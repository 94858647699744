import { LoanOfferResponse } from 'handlers/loanOffer';
import { EmployersLinkItemIds } from 'handlers/employmentData';
import { AlloyKycResult } from 'handlers/kyc';

import { CurrentFlow } from 'enums/CurrentFlow';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { ProfessionGroup } from 'enums/ProfessionGroup';

import Api, { FetchMethodType } from './Api';

export interface LoanOfferApplication {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  streetAddress: string;
  city: string;
  email: string;
  phoneNumber: string;
  state: string;
  zipCode: string;
  personalIdNumber: string;
  argyleState?: string;
  loanAmountRange: string;
  loanPurpose: string;
  creditScoreRange: string;
  totalAnnualIncome: number;
  additionalIncome?: number;
  isFullAddressSent: boolean;
  loanGoal?: string;
  referredBy: string;
  resumeLink: string;
}

export interface Employment {
  borrower_employer_name?: string;
  annual_income?: number;
  pay_frequency?: string;
  payroll_provider?: string;

  job_title?: string;
  employment_status?: string;
  employment_type?: string;
  hire_datetime?: string;
  base_pay_amount?: number;
  base_pay_period?: string;
}

export interface UtmTags {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

export interface DataForGettingLoanOffer {
  application: LoanOfferApplication;
  employment: Employment[];
  utm: UtmTags;
  employersLinkItemIds?: EmployersLinkItemIds;
  sessionToken?: string;
}

export interface DataForGettingLoanOfferInFinancialCheckup {
  application: {
    firstName: string;
    lastName: string;
    professionGroup: ProfessionGroup;
    email: string;
    phoneNumber: string;
    entityId: string;
    totalAnnualIncome: number;
    resumeLink: string;
  };
  employment: Employment[];
  currentFlow: CurrentFlow;
  sessionToken?: string;
}

export interface DataForGettingLoanOfferXSellFromCardApplication {
  applicationId: string;
  flow: CurrentFlow;
  resumeLink: string;
}

export interface LoanOfferProgress {
  step: LoanOfferStep;
  totalAccounts?: number;
  initialAccounts?: number;
  syncingAccounts?: number;
}

export enum LoanOfferStep {
  ValidatedInput = 'Validated Input',
  RunningDecisionEngine = 'Running Decision Engine',
  GettingCreditReport = 'Getting Credit Report',
  GettingTradelines = 'Getting Tradelines',
  GeneratingDebtProfile = 'Generating Debt Profile',
  CreatingHardOffer = 'Creating Hard Offer',
}

export interface VerificationResult {
  status: ApplicationStatusName;
  kycResult?: AlloyKycResult;
}

export interface LoanOfferApi {
  getLoanOffer(
    dataForGettingLoanOffer: DataForGettingLoanOffer | DataForGettingLoanOfferInFinancialCheckup,
    updateCallback?: (data: LoanOfferProgress) => void,
  ): Promise<LoanOfferResponse>;
  getLoanOfferXSell(
    dataForGettingLoanOffer: DataForGettingLoanOfferXSellFromCardApplication,
    updateCallback?: (data: LoanOfferProgress) => void,
  ): Promise<LoanOfferResponse>;
  verifyApplication(applicationId: string): Promise<VerificationResult>;
}

export default class LoanOfferRestApi extends Api implements LoanOfferApi {
  public async getLoanOffer(
    dataForGettingLoanOffer: DataForGettingLoanOffer | DataForGettingLoanOfferInFinancialCheckup,
    updateCallback?: (data: LoanOfferProgress) => void,
  ) {
    const result = this.fetchStream<LoanOfferProgress | LoanOfferResponse>(`/loanOffer`, {
      method: FetchMethodType.POST,
      body: dataForGettingLoanOffer,
    });

    let next;
    // eslint-disable-next-line no-restricted-syntax
    for await (next of result) {
      if (LoanOfferRestApi.isOfferProgress(next)) {
        updateCallback && updateCallback(next as LoanOfferProgress);
      } else {
        break;
      }
    }
    return next as LoanOfferResponse;
  }

  public async getLoanOfferXSell(
    dataForGettingLoanOffer: DataForGettingLoanOfferXSellFromCardApplication,
    updateCallback?: (data: LoanOfferProgress) => void,
  ) {
    const result = this.fetchStream<LoanOfferProgress | LoanOfferResponse>(`/loanOffer/xsell`, {
      method: FetchMethodType.POST,
      body: {
        ...dataForGettingLoanOffer,
      },
    });

    let next;
    // eslint-disable-next-line no-restricted-syntax
    for await (next of result) {
      if (LoanOfferRestApi.isOfferProgress(next)) {
        updateCallback && updateCallback(next as LoanOfferProgress);
      } else {
        break;
      }
    }
    return next as LoanOfferResponse;
  }

  public async verifyApplication(applicationId: string): Promise<VerificationResult> {
    return this.fetch<VerificationResult>(`/loanOffer/verify/${applicationId}`, {
      method: FetchMethodType.POST,
    }) as Promise<VerificationResult>;
  }

  private static isOfferProgress(result: LoanOfferProgress | LoanOfferResponse): result is LoanOfferProgress {
    return !!(result as LoanOfferProgress).step;
  }
}
