import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { EmailShareButton, FacebookMessengerShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import { ReactComponent as CrossIcon } from 'images/cross-icon.svg';
import { ReactComponent as CopyIcon } from 'images/copy-icon.svg';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';

import { ReactComponent as FacebookIcon } from 'images/share-modal/facebook.svg';
import { ReactComponent as FacebookMessengerIcon } from 'images/share-modal/facebook-messenger.svg';
import { ReactComponent as TwitterIcon } from 'images/share-modal/twitter.svg';
import { ReactComponent as EmailIcon } from 'images/share-modal/email.svg';
import { ReactComponent as MessagesIcon } from 'images/share-modal/messages.svg';

import Input from 'components/Input/Input';
import { ConversionType, trackConversion } from 'utils/analytics';
import { copyToClipboard } from 'utils/copyToClipboard';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './ShareModal.module.scss';

export const SHARE_MESSAGE =
  'I just signed up for Plannery, a new card just for healthcare professionals. Check it out!';

// react-share library doesn't provide messages share button
export const MessageShareButton = ({
  title,
  url,
  children,
  beforeOnClick,
}: {
  title: string;
  url: string;
  children: React.ReactNode;
  beforeOnClick?: () => void;
}) => {
  const handleClick = () => {
    if (beforeOnClick) {
      beforeOnClick();
    }
    window.open(`sms:/&body=${title} ${url}`);
  };

  return (
    <button className={styles.noStyleButton} type="button" onClick={handleClick}>
      {children}
    </button>
  );
};

const socialPlatforms = [
  {
    name: 'Facebook',
    button: FacebookShareButton,
    icon: FacebookIcon,
  },
  {
    name: 'Messenger',
    button: FacebookMessengerShareButton,
    icon: FacebookMessengerIcon,
  },
  {
    name: 'Email',
    button: EmailShareButton,
    icon: EmailIcon,
  },
  {
    name: 'Messages',
    button: MessageShareButton,
    icon: MessagesIcon,
  },
  {
    name: 'Twitter',
    button: TwitterShareButton,
    icon: TwitterIcon,
  },
];

interface SharedModalProps {
  referralLink: string;
  shareMessage: string;
  modalDescription?: string;
  handleClose: () => void;
}

const ShareModal = ({ referralLink, handleClose, shareMessage, modalDescription }: SharedModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [copyClicked, setCopyClicked] = useState(false);
  const { theme } = useLayoutTheme();

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.display = 'block';
      modalRef.current.classList.add(styles.show);
    }
  }, [modalRef.current]);

  const onClose = () => {
    modalRef.current?.classList.remove(styles.show);
    modalRef.current?.classList.add(styles.hide);
    handleClose();
  };

  const handleClick = (platform: string) => {
    analytics.track('Card CTA Share Link Clicked', { platform });
    trackConversion(ConversionType.CardShareSocialClick);
  };

  const onCopy = () => {
    setCopyClicked(true);
    copyToClipboard(referralLink, '', false);
    analytics.track('Card CTA Copy Link Clicked');
  };

  return (
    <>
      <div ref={modalRef} className={clsx(styles.container, styles[`theme__${theme}`])}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>Share to...</div>
          <CrossIcon className={styles.headerIcon} onClick={onClose} />
        </div>

        <p className={styles.waitlistLabel}>{modalDescription || 'Share link to move up on the waitlist.'}</p>

        <div className={styles.content}>
          {socialPlatforms.map((platform) => (
            <div className={styles.socialButton} key={platform.name}>
              <platform.button
                title={shareMessage}
                url={referralLink}
                appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
                beforeOnClick={() => handleClick(platform.name)}
              >
                <platform.icon className={styles.socialIcon} />
                <p className={styles.iconLabel}>{platform.name}</p>
              </platform.button>
            </div>
          ))}
        </div>

        {copyClicked ? (
          <div className={styles.copiedWrapper}>
            <div className={styles.iconWrapper}>
              <CheckIcon className={styles.customCheckIcon} />
            </div>
            <p className={styles.copiedLabel}>Copied to clipboard.</p>
          </div>
        ) : (
          <div className={styles.inputWrapper}>
            <Input readOnly value={referralLink} />
            <CopyIcon onClick={onCopy} className={styles.copyIcon} />
          </div>
        )}
      </div>
      <div className={styles.backdrop} />
    </>
  );
};

export default ShareModal;
